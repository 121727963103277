import { Box } from "@mui/material";
import { ChangeEvent, RefObject } from "react";
import { Form } from "react-bootstrap";

function truncateFileName(fileName: string): string {
  if (fileName.length > 20) {
    return `${fileName.substring(0, 15)}...${fileName.split(".").pop()}`;
  }
  return fileName;
}

export function ScanSelector({
  onFileSelectChange,
  onScanSelect,
  canvasRefs,
  fileNames,
  selImgIdx,
  widthPx,
}: {
  onFileSelectChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onScanSelect: (idx: number) => void;
  canvasRefs: RefObject<HTMLCanvasElement>[];
  fileNames: string[];
  selImgIdx: number;
  widthPx: number;
}) {
  return (
    <Box sx={{ width: "100%", height: "200px", overflowY: "scroll" }}>
      <Form.Control
        type="file"
        accept="image/*"
        multiple
        onChange={onFileSelectChange}
        size="lg"
      />
      {/* <input
        type="file"
        accept="image/*"
        multiple
        onChange={onFileSelectChange}
      ></input> */}
      <div
        style={{
          overflowX: "scroll",
          display: "flex",
          flexDirection: "row",
          width: widthPx,
          height: "100%",
        }}
      >
        {canvasRefs.map((ref, idx) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "10px",
              marginTop: "10px",
            }}
          >
            <canvas
              ref={ref}
              style={{
                alignSelf: "center",
                height: "70%",
                border:
                  selImgIdx == idx
                    ? "3px solid blue"
                    : "1px solid rgba(0, 0, 0, 0.2)",
                borderRadius: 5,
              }}
              onClick={() => {
                onScanSelect(idx);
              }}
            />
            <span
              style={{
                fontSize: "10px",
                marginTop: "2px",
                color: selImgIdx == idx ? "blue" : undefined,
                fontWeight: selImgIdx == idx ? "bold" : undefined,
              }}
            >
              {truncateFileName(fileNames[idx])}
            </span>
          </div>
        ))}
      </div>
    </Box>
  );
}
