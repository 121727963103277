export const imageFileToObjectURL = async (file: File): Promise<string> => {
  if (file.type !== "image/tiff") {
    return URL.createObjectURL(file);
  } else {
    return tiffFileToObjectURL(file);
  }
};

const tiffFileToObjectURL = async (file: File): Promise<string> => {
  const UTIF = await import("utif");
  const buffer = await file.arrayBuffer();
  const ifds = UTIF.decode(buffer);
  // If the tiff contains multiple images, we only use the first one (for now).
  const ifd = ifds[0];
  UTIF.decodeImage(buffer, ifd)
  const rgba = UTIF.toRGBA8(ifd);

  // TODO: This might double the memory usage, since the tiff is stored in a
  // canvas and and img later on.
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = ifd.width;
  canvas.height = ifd.height;
//   debugger;
  context!.putImageData(
    new ImageData(Uint8ClampedArray.from(rgba), ifd.width, ifd.height),
    0,
    0
  );
  return canvas.toDataURL();
};

export {};
