import update from "immutability-helper";
import React, {
  ChangeEvent,
  createRef,
  LegacyRef,
  RefObject,
  useCallback,
  useRef,
  useState,
} from "react";

import { Box } from "@mui/material";
// import cv, { Point, scaleAdd } from "@techstark/opencv-js";
import cv, { copyTo } from "@techstark/opencv-js";

import { Blotch, copyToClipboard } from "./app/Blotch";
import { BlotchTable, ShowCols } from "./app/BlotchTable";
import { BlotchVizPanel } from "./app/BlotchViz";
import { imageFileToObjectURL } from "./app/files";
import { MainCanvas, Point } from "./app/MainCanvas";
import { ScanSelector } from "./app/ScanSelector";
import { blotchFromSelectShape, ShapeSelect } from "./app/ShapeSelect";
import { ColorMode, SelectionMode, SideBar } from "./app/SideBar";
import { SECTION_STYLE } from "./app/Style";

export function Trigit() {
  // user chooses images files -> input.onChange triggers and creates the canvas refs for the tiles
  // and a URL object for each image -> render a (hidden) img tag for each image -> in img.onload
  // use the img tag to construct a cv.Mat for each image.
  // cv.onRuntimeInitialized = () => {
  //   (window as any)._cv = cv;
  // };

  const [imgURLList, setImgURLList] = useState<string[]>([]);
  const [thumbRefList, setThumbRefList] = useState<
    RefObject<HTMLCanvasElement>[]
  >([]);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const [imgMatList, setImgMatList] = useState<(any | null)[]>([]);

  const [selImgIdx, setSelImgIdx] = useState<number>(0);

  const [blotches, setBloches] = useState<Blotch[]>([]);
  const [showCols, setShowCols] = useState<ShowCols>(new ShowCols());
  const [scale, setScale] = useState<number>(0.25);
  const [showTable, setShowTable] = useState<boolean>(true);
  const [selectionMode, setSelectionMode] = useState<SelectionMode>("circle");
  const [selectionFixedArea, setSelectionFixedArea] = useState<boolean>(false);
  const [selectionStamp, setSelectionStamp] = useState<ShapeSelect | null>(
    null
  );
  const [colorMode, setColorMode] = useState<ColorMode>("rgb");
  const [floodThreshold, setFloodThreshold] = useState<number>(40);

  const debugCanvasRef = useRef<HTMLCanvasElement>();

  const updateBlotches = useCallback(
    (newBlotches: Blotch[]) => {
      copyToClipboard(newBlotches, showCols, false);
      setBloches(newBlotches);
    },
    [showCols]
  );

  const onFileSelectChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      // console.log(event?.target.files);
      const filesArray = Array.from(event.target.files!);
      setFileNames(filesArray.map((f) => f.name));
      setThumbRefList(filesArray.map((f) => createRef<HTMLCanvasElement>()));
      const objectURLs = await Promise.all(
        filesArray.map(async (f) => {
          return await imageFileToObjectURL(f);
        })
      );
      setImgURLList(objectURLs);
      setImgMatList(filesArray.map((f) => null));
      setBloches([]);
    },
    [setThumbRefList, setImgURLList, setImgMatList, setFileNames, setBloches]
  );

  const onScanSelect = useCallback(
    (idx: number) => {
      setSelImgIdx(idx);
      setBloches([]);
    },
    [setSelImgIdx]
  );

  const onShowColsChange = useCallback(
    (newShowCols: ShowCols) => {
      copyToClipboard(blotches, newShowCols, false);
      setShowCols(newShowCols);
    },
    [blotches, setShowCols]
  );

  const onColorModeChange = useCallback(
    (newColorMode: ColorMode) => {
      if (newColorMode == colorMode) return;

      if (newColorMode == "rgb") {
        setShowCols({
          ...showCols,
          muCMYK: false,
          muRGB: true,
          percRGB: true,
          sigmaRGB: true,
        });
      } else if (newColorMode == "cmyk") {
        setShowCols({
          ...showCols,
          muCMYK: true,
          muRGB: false,
          percRGB: false,
          sigmaRGB: false,
        });
      }
      setColorMode(newColorMode);
    },
    [colorMode, setColorMode]
  );

  // const handleSelectionModeChange = useCallback(
  //   (newMode: SelectionMode) => {
  //     if (newMode == "pick") {

  //     }

  //     setSelectionMode(newMode);
  //   },
  //   [setSelectionMode]
  // );

  // const onNewCircle = useCallback(
  //   (center: Point, radius: number, scale: number) => {
  //     const img = imgMatList[selImgIdx];

  //     // image on canvas is scaled down, scale up to original image size
  //     center = {
  //       x: center.x / scale,
  //       y: center.y / scale,
  //     };
  //     radius = radius / scale;

  //     const roiRect = new cv.Rect(
  //       center.x - radius,
  //       center.y - radius,
  //       radius * 2,
  //       radius * 2
  //     );
  //     const roi = img?.roi(roiRect)!; // cut a rectangle out of im which contains the circle drawn

  //     const newBlotches = [...blotches, new Blotch(center, radius, roi)];

  //     updateBlotches(newBlotches);

  //     // setBloches(newBlotches);

  //     // navigator.clipboard.writeText(getClipboardStr(newBlotches));
  //   },
  //   [selImgIdx, imgMatList, blotches]
  // );

  const onNewShapeSelect = useCallback(
    (select: ShapeSelect, scale: number) => {
      // If we're in fixed area selection mode, but there is not yet a selection stamp,
      // set the shape select as the stamp and *don't* colour pick.
      if (selectionFixedArea && selectionStamp == null) {
        setSelectionStamp(select);
        return;
      }
      const imgMat = imgMatList[selImgIdx]!;
      const bloch = blotchFromSelectShape(
        imgMat,
        select,
        scale,
        floodThreshold
      );
      const newBlotches = [...blotches, bloch];
      updateBlotches(newBlotches);
    },
    [
      selImgIdx,
      imgMatList,
      blotches,
      updateBlotches,
      floodThreshold,
      setSelectionStamp,
      selectionFixedArea,
      selectionStamp,
    ]
  );

  const toggleSelectionFixedArea = useCallback(() => {
    setSelectionFixedArea((prev) => {
      // When turning off fixed area, clear the selection stamp.
      if (prev == true) {
        setSelectionStamp(null);
      }
      return !prev;
    });
  }, [setSelectionFixedArea, setSelectionStamp]);

  const handleSetSelectionMode = useCallback(
    (mode: SelectionMode) => {
      setSelectionMode((prev) => {
        // When the selection mode changes, clear the selection stamp.
        if (prev != mode) {
          setSelectionStamp(null);
        }
        return mode;
      });
    },
    [setSelectionMode, setSelectionStamp]
  );

  const handleReset = useCallback(() => {
    // TODO: probably need to manually free the cv.Mats when doing this.
    setBloches((prevBlotches: Blotch[]) => {
      prevBlotches.forEach((bloch) => {
        bloch.vizMat.delete();
      });
      return [];
    });
  }, [setBloches]);

  const handleCopy = useCallback(() => {
    copyToClipboard(blotches, showCols, true);
  }, [blotches, showCols]);

  const handleExport = useCallback(() => {
    blotches.forEach((blotch: Blotch, idx: number) => {
      const canvas = document.createElement("canvas");
      cv.imshow(canvas, blotch.vizMat);
      const dataURL = canvas.toDataURL();
      const link = document.createElement("a");
      link.href = dataURL;
      link.style.display = "none";
      link.download = `${blotch.name || idx}.png`;
      document.body.appendChild(link);
      link.click();
    });
  }, [blotches]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <div
        style={Object.assign({}, SECTION_STYLE, {
          width: "98%",
          height: "98%",
          overflow: "hidden",
          // overflow: "scroll",
          display: "flex",
          flexDirection: "row",
          borderRadius: "30px",
          boxShadow: "0px 0px 20px 5px rgba(0,0,0,0.5)",
        })}
      >
        <div>
          <SideBar
            showCols={showCols}
            scale={scale}
            showTable={showTable}
            selectionMode={selectionMode}
            selectionFixedArea={selectionFixedArea}
            colorMode={colorMode}
            floodThreshold={floodThreshold}
            setShowCols={onShowColsChange}
            setScale={setScale}
            setShowTable={setShowTable}
            setSelectionMode={handleSetSelectionMode}
            toggleSelectionFixedArea={toggleSelectionFixedArea}
            setColorMode={onColorModeChange}
            setFloodThreshold={setFloodThreshold}
            onReset={handleReset}
            onCopy={handleCopy}
            onExport={handleExport}
          />
        </div>
        <Box sx={{ height: "100%", width: "100%" }}>
          <div style={Object.assign({}, SECTION_STYLE)}>
            <ScanSelector
              onFileSelectChange={onFileSelectChange}
              onScanSelect={onScanSelect}
              canvasRefs={thumbRefList}
              fileNames={fileNames}
              selImgIdx={selImgIdx}
              widthPx={window.innerWidth - 150}
            />
          </div>
          {imgURLList.map((url, idx) => (
            <img
              hidden
              src={url}
              onLoad={(e) => {
                const imgRGBA = cv.imread(e.target as HTMLElement);
                const img = new cv.Mat();
                cv.cvtColor(imgRGBA, img, cv.COLOR_RGBA2RGB);
                const imgThumb = new cv.Mat();
                cv.resize(
                  img,
                  imgThumb,
                  new cv.Size(0, 0),
                  0.05,
                  0.05,
                  cv.INTER_AREA
                );
                cv.imshow(thumbRefList[idx].current!, imgThumb);

                let newMats = [...imgMatList];
                newMats[idx] = img;
                setImgMatList(newMats);

                imgThumb.delete();
              }}
            />
          ))}
          {imgMatList.length > 0 && imgMatList[selImgIdx] && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // flex: 1,
                // height: "100%",
              }}
            >
              <div
                style={Object.assign({}, SECTION_STYLE, {
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px",
                  width: window.innerWidth * 0.98 - 200,
                  height: window.innerHeight - 400,
                  overflow: "scroll",
                })}
              >
                <MainCanvas
                  image={imgMatList[selImgIdx]!}
                  scale={scale}
                  blotchBoundingRects={blotches.map(
                    (blotch) => blotch.boundingRect
                  )}
                  selectionMode={selectionMode}
                  selectionStamp={selectionStamp}
                  onNewShapeSelect={onNewShapeSelect}
                />
                {showTable && (
                  <BlotchTable
                    blotches={blotches}
                    cols={showCols}
                    onSetName={(blotchIdx: number, newName: string) =>
                      updateBlotches(
                        update(blotches, {
                          [blotchIdx]: { name: { $set: newName } },
                        })
                      )
                    }
                    onDeleteBlotch={(blotchIdx: number) =>
                      // TODO: May need to cal blotch.vizMat.delete() here.
                      updateBlotches(
                        update(blotches, {
                          $splice: [[blotchIdx, 1]],
                        })
                      )
                    }
                  />
                )}
              </div>
              <div style={Object.assign({}, SECTION_STYLE, {})}>
                <BlotchVizPanel vizMats={blotches.map((b) => b.vizMat)} />
              </div>
            </Box>
          )}
          {/* <canvas ref={debugCanvasRef as LegacyRef<HTMLCanvasElement>} /> */}
        </Box>
      </div>
    </Box>
  );
}
