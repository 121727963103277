import "@fontsource/nunito/300.css";
import "@fontsource/nunito/500.css";
import "@fontsource/nunito/800.css";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Snackbar,
} from "@mui/material";
import update from "immutability-helper";
import React, { useEffect } from "react";
import CircleIcon from "../static/circle-icon.svg";
import ColorsIcon from "../static/colors-icon.svg";
import ColumnsIcon from "../static/columns-icon.svg";
import CopyIcon from "../static/copy-icon.svg";
import ExportIcon from "../static/export-icon.svg";
import HelpIcon from "../static/help-icon.svg";
import HelpPageImg from "../static/help-page.png";
import HomeIcon from "../static/home-icon.svg";
import PickIcon from "../static/pick-icon.svg";
import PolyIcon from "../static/poly-icon.svg";
import RectIcon from "../static/rect-icon.svg";
import SelectionIcon from "../static/selection-icon.svg";
import ThresholdIcon from "../static/threshold-icon.svg";
import TrashIcon from "../static/trash-icon.svg";
import TrigitLogo from "../static/trigit.svg";
import ZoomIcon from "../static/zoom-icon.svg";
import { ShowCols } from "./BlotchTable";
import { Col, Row } from "./layout";
import { SECTION_STYLE } from "./Style";
import { MaybeWithTooltip, SVGButton, TrigitButton, Widget } from "./Utils";

export type SelectionMode = "circle" | "rect" | "poly" | "pick";

export type ColorMode = "rgb" | "cmyk" | "hsv" | "cielab";

const SidebarBlock = ({
  children,
  header,
  icon = undefined,
  sx = {},
}: {
  children: React.ReactNode;
  icon?: string;
  header?: string;
  sx?: any;
}) => (
  <Box
    sx={Object.assign({}, SECTION_STYLE, {
      marginLeft: "10px",
      marginRight: "5px",
      paddingLeft: "5px",
      paddingRight: "5px",
      maxWidth: "160px",
      marginBottom: 3,
      paddingBottom: 2,
    })}
  >
    <Row
      sx={{
        fontSize: "18px",
        margin: "10px",
        width: "140px",
        fontWeight: 500,
      }}
    >
      {icon && <img src={icon} width="27px" style={{ marginRight: "5px" }} />}
      {header}
    </Row>
    {children}
  </Box>
);

const ButtonWidget = ({
  children,
  icon,
  onClick,
  tooltip,
}: {
  children: React.ReactNode;
  icon: string;
  onClick: () => void;
  tooltip?: string;
}) => (
  <MaybeWithTooltip tooltip={tooltip}>
    <Button
      sx={{
        boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.3)",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        height: "30px",
        minHeight: "30px",
        px: 1,
        borderRadius: "7px",
        mb: 1,
        fontWeight: 500,
        fontSize: "8",
        width: "100%",
        textTransform: "none",
        color: "black",
        display: "flex",
        justifyContent: "start",
      }}
      onClick={onClick}
    >
      <img src={icon} width="20px" style={{ marginRight: "5px" }} />
      {children}
    </Button>
  </MaybeWithTooltip>
);

const PickSensitivitySlider = ({
  floodThreshold,
  setFloodThreshold,
}: {
  floodThreshold: number;
  setFloodThreshold: (floodThreshold: number) => void;
}) => {
  const SENSITIVITY_VALUES = [
    0, 10, 20, 30, 40, 50, 60, 70, 75, 80, 85, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100,
  ];

  const SENSITIVITY_MARKS = SENSITIVITY_VALUES.map(
    (v: number, idx: number) => ({
      value: idx,
    })
  );

  // Find the index of the first SENSITIVITY_VALUE >= the given number.
  const indexOfFirstLargerThan = (sensitivityPerc: number) => {
    const idx = SENSITIVITY_VALUES.findIndex((v) => v >= sensitivityPerc);
    return idx === -1 ? SENSITIVITY_VALUES.length - 1 : idx;
  };

  const sensitivityPercToThreshold = (perc: number) =>
    ((100 - perc) * 255) / 100;

  const thresholdToSensitivityPerc = (threshold: number) =>
    100 - Math.round((floodThreshold * 100) / 255);

  return (
    <Widget icon={ThresholdIcon}>
      <Slider
        step={null}
        max={SENSITIVITY_VALUES.length - 1}
        value={indexOfFirstLargerThan(
          thresholdToSensitivityPerc(floodThreshold)
        )}
        onChange={(e, v) =>
          setFloodThreshold(
            sensitivityPercToThreshold(SENSITIVITY_VALUES[v as number])
          )
        }
        marks={SENSITIVITY_MARKS}
        valueLabelDisplay="auto"
        valueLabelFormat={(v: number) => `${SENSITIVITY_VALUES[v]}%`}
      />
    </Widget>
  );
};

export function SideBar({
  showCols,
  scale,
  showTable,
  selectionMode,
  selectionFixedArea,
  colorMode,
  floodThreshold,
  setShowCols,
  setScale,
  setShowTable,
  setSelectionMode,
  toggleSelectionFixedArea,
  setColorMode,
  setFloodThreshold,
  onReset,
  onCopy,
  onExport,
}: {
  showCols: ShowCols;
  scale: number;
  showTable: boolean;
  selectionMode: SelectionMode;
  selectionFixedArea: boolean;
  colorMode: ColorMode;
  floodThreshold: number;
  setShowCols: (showCols: ShowCols) => void;
  setScale: (scale: number) => void;
  setShowTable: (showTable: boolean) => void;
  setSelectionMode: (selectionMode: SelectionMode) => void;
  toggleSelectionFixedArea: () => void;
  setColorMode: (colorMode: ColorMode) => void;
  setFloodThreshold: (floodThreshold: number) => void;
  onReset: () => void;
  onCopy: () => void;
  onExport: () => void;
}) {
  const [copyToastOpen, setCopyToastOpen] = React.useState(false);

  const toggleCol = (col: keyof ShowCols) => {
    console.log("toggleCol", col);
    setShowCols(update(showCols, { [col]: { $set: !showCols[col] } }));
  };

  const InnerButton = React.useMemo(() => {
    const comp = ({ label, col }: { label: string; col: keyof ShowCols }) => (
      <TrigitButton
        // style={{
        //   backgroundColor: showCols[col] ? "lightslategray" : undefined,
        //   border: "1px solid gray",
        //   marginRight: 3,
        // }}
        width="30px"
        height="30px"
        onClick={(e) => {
          toggleCol(col);
          e.stopPropagation();
        }}
        active={showCols[col]}
        tooltip={showCols[col] ? "Hide" : "Show"}
      >
        {label}
      </TrigitButton>
    );
    return comp;
  }, [toggleCol, showCols]);

  return (
    <div
      style={{
        width: 200,
        fontFamily: "Nunito",
        fontWeight: 300,
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "98vh",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }} ml={2} mt={3} mr={4}>
        <img src={TrigitLogo} width={250} />
      </Box>
      <Box mt={7} />
      <SidebarBlock header={"General"} icon={HomeIcon}>
        <Box sx={{ width: "80%", mx: "auto" }}>
          <Widget
            icon={ZoomIcon}
            tooltip="scroll the bar to enlarge (zoom in) or minimize (zoom out) your image size"
          >
            <Slider
              min={-5}
              max={2}
              step={1}
              scale={(v) => 2 ** v}
              value={Math.log2(scale)}
              onChange={(e, v) => setScale(2 ** (v as number))}
            />
          </Widget>
          <ButtonWidget
            icon={ColumnsIcon}
            onClick={() => setShowTable(!showTable)}
          >
            {showTable ? "Hide table" : "Show table"}
          </ButtonWidget>
          <ButtonWidget
            icon={TrashIcon}
            onClick={onReset}
            tooltip="clear selection area and table values"
          >
            Reset
          </ButtonWidget>
          <ButtonWidget
            icon={CopyIcon}
            onClick={() => {
              onCopy();
              setCopyToastOpen(true);
            }}
            tooltip="copy table to clipboard"
          >
            Copy
          </ButtonWidget>
          <Snackbar
            open={copyToastOpen}
            autoHideDuration={3000}
            onClose={() => setCopyToastOpen(false)}
            message="Copied to clipboard"
          />
          <ButtonWidget
            icon={ExportIcon}
            onClick={onExport}
            tooltip="export selection areas as PNGs"
          >
            Export
          </ButtonWidget>
          <ButtonWidget
            icon={HelpIcon}
            onClick={() => {
              window.open(HelpPageImg, "_blank")!.focus();
            }}
            tooltip="help"
          >
            Help
          </ButtonWidget>
        </Box>
      </SidebarBlock>
      <SidebarBlock header={"Selection"} icon={SelectionIcon}>
        <Row
          sx={{
            justifyContent: "space-between",
            width: "120px",
            margin: "auto",
          }}
        >
          <SVGButton
            src={CircleIcon}
            active={selectionMode == "circle"}
            onClick={() => setSelectionMode("circle")}
          />
          <SVGButton
            src={RectIcon}
            active={selectionMode == "rect"}
            onClick={() => setSelectionMode("rect")}
          />
          {/* <SVGButton
              src={PolyIcon}
              active={selectionMode == "poly"}
              onClick={() => setSelectionMode("poly")}
            /> */}
          <SVGButton
            src={PickIcon}
            onClick={() => setSelectionMode("pick")}
            active={selectionMode == "pick"}
          />
        </Row>
        {selectionMode == "pick" && (
          <Box sx={{ width: "80%", mx: "auto", mt: 2 }}>
            <PickSensitivitySlider
              floodThreshold={floodThreshold}
              setFloodThreshold={setFloodThreshold}
            />
          </Box>
        )}
        {(selectionMode == "circle" || selectionMode == "rect") && (
          <Col>
            <TrigitButton
              onClick={toggleSelectionFixedArea}
              active={selectionFixedArea}
              sx={{ minWidth: "120px", mt: 1.5 }}
              height="30px"
              tooltip={`Use ${
                selectionFixedArea ? "variable" : "fixed"
              } sized regions`}
            >
              {`${selectionFixedArea ? "Vary" : "Fix"} area`}
            </TrigitButton>
          </Col>
        )}
      </SidebarBlock>
      <SidebarBlock header={"Color space"} icon={ColorsIcon}>
        <Col>
          <TrigitButton
            onClick={() => {
              const val = !showCols.muRGB;
              setShowCols(
                update(showCols, {
                  muRGB: { $set: val },
                  sigmaRGB: { $set: val },
                  percRGB: { $set: val },
                })
              );
            }}
            active={showCols.muRGB}
            sx={{ minWidth: "120px", mt: 1 }}
            height="30px"
            tooltip={showCols.muRGB ? "Hide" : "Show"}
          >
            RGB
          </TrigitButton>
          <TrigitButton
            onClick={() => toggleCol("muCMYK")}
            active={showCols.muCMYK}
            sx={{ minWidth: "120px", mt: 1 }}
            height="30px"
            tooltip={showCols.muCMYK ? "Hide" : "Show"}
          >
            CMYK
          </TrigitButton>
          <TrigitButton
            onClick={() => toggleCol("muLAB")}
            active={showCols.muLAB}
            sx={{ minWidth: "120px", mt: 1 }}
            height="30px"
            tooltip={showCols.muLAB ? "Hide" : "Show"}
          >
            CIELAB
          </TrigitButton>
          <TrigitButton
            onClick={() => toggleCol("muHSV")}
            active={showCols.muHSV}
            sx={{ minWidth: "120px", mt: 1 }}
            height="30px"
            tooltip={showCols.muHSV ? "Hide" : "Show"}
          >
            HSV
          </TrigitButton>
        </Col>
      </SidebarBlock>
      <SidebarBlock header={"Columns"} icon={ColumnsIcon}>
        <Col>
          {colorMode === "rgb" && (
            <>
              <TrigitButton
                // style={{
                //   display: "flex",
                //   borderRadius: "5px",
                //   backgroundColor: showCols.muRGB ? "lightgrey" : undefined,
                //   border: "1px solid grey",
                //   padding: "4px",
                //   paddingLeft: "7px",
                //   color: "black",
                //   textTransform: "none",
                //   width: "120px",
                // }}
                onClick={() => toggleCol("muRGB")}
                active={showCols.muRGB}
                sx={{ minWidth: "120px", mt: 1 }}
                height="35px"
                // tooltip={showCols.muRGB ? "Hide" : "Show"}
              >
                <span style={{ marginRight: 10, marginLeft: 5 }}>μ</span>{" "}
                <InnerButton label="R" col="muR" />
                <InnerButton label="G" col="muG" />
                <InnerButton label="B" col="muB" />
              </TrigitButton>
              <TrigitButton
                // sx={{
                //   display: "flex",
                //   borderRadius: "5px",
                //   backgroundColor: showCols.percRGB ? "lightgrey" : undefined,
                //   border: "1px solid grey",
                //   padding: "4px",
                //   paddingLeft: "7px",
                //   color: "black",
                //   width: "120px",
                // }}
                onClick={() => toggleCol("percRGB")}
                active={showCols.percRGB}
                sx={{ minWidth: "120px", mt: 1 }}
                height="35px"
                // tooltip={showCols.percRGB ? "Hide" : "Show"}
              >
                <span style={{ marginRight: 10, marginLeft: 5 }}>%</span>
                <InnerButton label="R" col="percR" />
                <InnerButton label="G" col="percG" />
                <InnerButton label="B" col="percB" />
              </TrigitButton>
              <TrigitButton
                // sx={{
                //   display: "flex",
                //   borderRadius: "5px",
                //   backgroundColor: showCols.percRGB ? "lightgrey" : undefined,
                //   border: "1px solid grey",
                //   padding: "4px",
                //   paddingLeft: "7px",
                //   color: "black",
                //   textTransform: "none",
                //   width: "120px",
                // }}
                onClick={() => toggleCol("sigmaRGB")}
                active={showCols.sigmaRGB}
                sx={{ minWidth: "120px", mt: 1 }}
                height="35px"
                // tooltip={showCols.sigmaRGB ? "Hide" : "Show"}
              >
                <span style={{ marginRight: 10, marginLeft: 5 }}>σ</span>
                <InnerButton label="R" col="sigmaR" />
                <InnerButton label="G" col="sigmaG" />
                <InnerButton label="B" col="sigmaB" />
              </TrigitButton>
              <Box mt={0.5} />
            </>
          )}
          {/* <Box mt={1} /> */}
          {/* <Box
            sx={{
              minHeight: "1px",
              backgroundColor: "rgba(0,0,0,0.5)",
              width: "60%",
              maxWidth: "1000px",
              boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.5)",
              my: 1,
            }}
          /> */}
          <Box
            mt={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "120px",
            }}
          >
            <TrigitButton
              onClick={() => toggleCol("name")}
              active={showCols.name}
              tooltip={showCols.name ? "Hide" : "Show"}
            >
              ID
            </TrigitButton>
            <TrigitButton
              onClick={() => toggleCol("numPix")}
              active={showCols.numPix}
              tooltip={showCols.numPix ? "Hide" : "Show"}
            >
              px
            </TrigitButton>
            <TrigitButton
              onClick={() => toggleCol("hex")}
              active={showCols.hex}
              tooltip={showCols.hex ? "Hide" : "Show"}
            >
              hex
            </TrigitButton>
          </Box>
        </Col>
      </SidebarBlock>
    </div>
  );
}
