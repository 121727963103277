import { cvhalDFT } from "mirada";
import React, { useState, useRef, useMemo, useEffect } from "react";
import cv from "@techstark/opencv-js";
import { Box } from "@mui/material";
import { SelectionMode } from "./SideBar";
import {
  centerShapeSelectStampAt,
  clickUpdateSelectShape,
  dragUpdateSelectShape,
  renderSelectShape,
  ShapeSelect,
  startSelectShape,
} from "./ShapeSelect";

const CIRCLE_THICKNESS = 1;

export type Point = {
  x: number;
  y: number;
};

function getMousePosOnCanvas(
  canvas: HTMLCanvasElement,
  event: React.MouseEvent
): Point {
  let rect = canvas.getBoundingClientRect();
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
}

function drawCircle(
  ctx: CanvasRenderingContext2D,
  locXY: Point,
  radius: number,
  colour: string
): void {
  ctx.beginPath();
  ctx.strokeStyle = colour;
  ctx.arc(locXY.x, locXY.y, radius, 0, 2 * Math.PI);
  ctx.closePath();
  ctx.stroke();
}

type MainCanvasProps = {
  /** Currently selected full sized image to display/work on */
  image: cv.Mat;
  /** Scale at which to display `image` */
  scale: number;
  /** Bounding rectangles for previously selected blotches to display on top of `image` */
  blotchBoundingRects: cv.Rect[];
  /** Selection mode for selecting new blotches */
  selectionMode: SelectionMode;
  selectionStamp: ShapeSelect | null;
  /** Callback when a new blotch is selected */
  onNewShapeSelect: (select: ShapeSelect, scale: number) => void;
};

export function MainCanvas({
  image,
  scale,
  blotchBoundingRects,
  selectionMode,
  selectionStamp,
  onNewShapeSelect,
}: MainCanvasProps) {
  // The cv.Mat which will be displayed on the canvas - this is a copy of `image`, but
  // with the scale applied.
  const [viewImgMat, setViewImgMat] = useState<cv.Mat | null>(null);
  const [amDrawing, setAmDrawing] = useState<boolean>(false);

  const shapeSelectRef = React.useRef<ShapeSelect | null>(null);

  const canvasRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (shapeSelectRef.current) {
          shapeSelectRef.current = null;
          cv.imshow(canvasRef.current!, viewImgMat!);
          setAmDrawing(false);
        }
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [canvasRef, viewImgMat, shapeSelectRef, setAmDrawing]);

  useEffect(() => {
    const img = new cv.Mat();
    cv.resize(image, img, new cv.Size(0, 0), scale, scale, cv.INTER_AREA);

    blotchBoundingRects.forEach((rect) => {
      cv.rectangle(
        img,
        new cv.Point(rect.x * scale, rect.y * scale),
        new cv.Point(
          (rect.x + rect.width) * scale,
          (rect.y + rect.height) * scale
        ),
        new cv.Scalar(255, 0, 255, 255),
        1,
        cv.LINE_AA
      );
    });
    // debugger;
    canvasRef.current!.width = img.size().width;
    canvasRef.current!.height = img.size().height;
    cv.imshow(canvasRef.current!, img);
    setViewImgMat((prev) => {
      if (prev !== null) {
        prev.delete();
      }
      return img;
    });
  }, [image, scale, blotchBoundingRects]);

  const onMouseDown = React.useCallback(
    (event: React.MouseEvent) => {
      const pos = getMousePosOnCanvas(canvasRef.current!, event);
      const isOffCanvas =
        pos.x < 0 ||
        pos.y < 0 ||
        pos.x > canvasRef.current!.width ||
        pos.y > canvasRef.current!.height;

      // Fixed area selection handling.
      if (selectionStamp !== null) {
        if (isOffCanvas) {
          return;
        }
        const select = centerShapeSelectStampAt(selectionStamp, pos);
        onNewShapeSelect(select, scale);
        return;
      }

      // Variable area selection handling.
      if (!amDrawing && isOffCanvas) {
        return;
      }
      if (selectionMode === "pick") {
        const shapeSelect = startSelectShape(
          selectionMode,
          getMousePosOnCanvas(canvasRef.current!, event)
        );
        onNewShapeSelect(shapeSelect, scale);
        return;
      }

      if (!amDrawing) {
        const shapeSelect = startSelectShape(
          selectionMode,
          getMousePosOnCanvas(canvasRef.current!, event)
        );
        renderSelectShape(shapeSelect, viewImgMat!, canvasRef.current!);
        // setShapeSelect(shapeSelect);
        shapeSelectRef.current = shapeSelect;
        setAmDrawing(true);
      } else {
        const select = clickUpdateSelectShape(
          // shapeSelect!,
          shapeSelectRef.current!,
          getMousePosOnCanvas(canvasRef.current!, event)
        );
        if (select.isComplete) {
          setAmDrawing(false);
          // setShapeSelect(null);
          shapeSelectRef.current = null;
          cv.imshow(canvasRef.current!, viewImgMat!);
          onNewShapeSelect(select, scale);
        }
      }
    },
    [
      amDrawing,
      onNewShapeSelect,
      scale,
      selectionMode,
      // shapeSelect,
      shapeSelectRef,
      viewImgMat,
      // setShapeSelect,
    ]
  );

  const onMouseMove = React.useCallback(
    (event: React.MouseEvent) => {
      const pos = getMousePosOnCanvas(canvasRef.current!, event);

      // Fixed area selection handling.
      if (selectionStamp !== null) {
        const select = centerShapeSelectStampAt(selectionStamp, pos);
        renderSelectShape(select, viewImgMat!, canvasRef.current!);
      }

      // Variable area selection handling.
      if (!amDrawing) {
        return;
      }
      const select = dragUpdateSelectShape(
        // shapeSelect!,
        shapeSelectRef.current!,
        pos
      );
      // setShapeSelect(select);
      shapeSelectRef.current = select;
      renderSelectShape(select, viewImgMat!, canvasRef.current!);
    },
    [amDrawing, viewImgMat, canvasRef, shapeSelectRef]
  );

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove as any);
    window.addEventListener("mousedown", onMouseDown as any);
    return () => {
      window.removeEventListener("mousemove", onMouseMove as any);
      window.removeEventListener("mousedown", onMouseDown as any);
    };
  }, [onMouseMove, onMouseDown]);

  return (
    <Box
      sx={{
        // width: `${window.innerWidth - 150 - 500}px`,
        // width: "100%",
        overflow: "scroll",
      }}
    >
      <Box>
        <canvas
          ref={canvasRef as React.LegacyRef<HTMLCanvasElement>}
          style={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: 5,
          }}
          // onMouseDown={onMouseDown}
          // onMouseDown={(
          //   event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
          // ) => {
          //   let canvas: HTMLCanvasElement = event.target as HTMLCanvasElement;

          //   if (amDrawing) {
          //     setAmDrawing(false);
          //     // return;

          //     // let imgScale = this.props.uiState.getActiveimage()?.getZoomratiosrcimg()! / this.props.uiState.getActiveimage()?.getZoomratioviewimg()!;

          //     // this.postCircle(
          //     //   {
          //     //     x: this.circleCenter!.x * imgScale,
          //     //     y: this.circleCenter!.y * imgScale,
          //     //   },
          //     //   this.getRadius(this.circleCenter!, this.getMousePosOnCanvas(canvas, event)) * imgScale
          //     // );
          //     let pointer = getMousePosOnCanvas(canvas, event);

          //     let radius = getRadius(drawOrigin!, pointer);

          //     cv.imshow(canvasRef.current!, viewImgMat!);
          //     onNewCircle(drawOrigin!, radius, scale);
          //     return;
          //   }
          //   setAmDrawing(true);
          //   let pointer = getMousePosOnCanvas(canvas, event);
          //   setDrawOrigin(pointer);

          //   const imgDisp = new cv.Mat();
          //   viewImgMat!.copyTo(imgDisp);
          //   cv.circle(
          //     imgDisp,
          //     new cv.Point(pointer.x, pointer.y),
          //     2,
          //     [0, 255, 0, 0],
          //     CIRCLE_THICKNESS,
          //     cv.LINE_AA
          //   );
          //   cv.imshow(canvasRef.current!, imgDisp);
          //   imgDisp.delete();

          //   // let ctx = canvas.getContext("2d")!;

          //   // drawCircle(ctx, pointer, 4, 'black');
          //   // drawCircle(ctx, pointer, 6, 'black');
          //   // drawCircle(ctx, pointer, 5, 'white');
          // }}
          // onMouseMove={onMouseMove}
          // onMouseMove={(event) => {
          //   let canvas: HTMLCanvasElement = event.target as HTMLCanvasElement;

          //   if (!amDrawing) {
          //     return;
          //   }

          //   let pointer = getMousePosOnCanvas(canvas, event);

          //   let radius = getRadius(drawOrigin!, pointer);

          //   if (radius > 2) {
          //     const imgDisp = new cv.Mat();
          //     viewImgMat!.copyTo(imgDisp);
          //     cv.circle(
          //       imgDisp,
          //       new cv.Point(drawOrigin!.x, drawOrigin!.y),
          //       radius,
          //       [0, 255, 0, 255],
          //       CIRCLE_THICKNESS,
          //       cv.LINE_AA
          //     );
          //     cv.imshow(canvasRef.current!, imgDisp);
          //     imgDisp.delete();

          //     // let ctx = canvas.getContext("2d")!;

          //     // ctx.clearRect(0, 0, canvas.width, canvas.height);
          //     // // ctx.drawImage(this.state.img!, 0, 0, this.state.img!.width * this.imgScale, this.state.img!.height * this.imgScale);
          //     // ctx.drawImage(this.imgElement, 0, 0);
          //     // this.drawSelection(ctx, this.circleCenter!, radius);
          //   }
          // }}
        />
      </Box>
    </Box>
  );
}
