import React from 'react';

const BOX_SHADOW_STR: string =
  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";

export const SECTION_STYLE: React.CSSProperties = {
    boxShadow: BOX_SHADOW_STR,
    borderRadius: "10px",
    backgroundColor: "#F1F3F6",
    display: "inline-block"
}

export const IMG_CANVAS_STYLE: React.CSSProperties = {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: 5,
    margin: "5px",
}
