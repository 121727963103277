import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";

export const Row = ({
  children,
  sx = {},
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) => (
  <Box
    sx={{ display: "flex", flexDirection: "row", alignItems: "center", ...sx }}
  >
    {children}
  </Box>
);

export const Col = ({
  children,
  sx = {},
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      ...sx,
    }}
  >
    {children}
  </Box>
);
