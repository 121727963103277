import { Blotch, getCMYK, getHex, getHSV, getLAB } from "./Blotch";

export class ShowCols {
  name: boolean = true;
  muRGB: boolean = true;
  muR: boolean = true;
  muG: boolean = true;
  muB: boolean = true;
  percRGB: boolean = true;
  percR: boolean = true;
  percG: boolean = true;
  percB: boolean = true;
  sigmaRGB: boolean = true;
  sigmaR: boolean = true;
  sigmaG: boolean = true;
  sigmaB: boolean = true;
  muCMYK: boolean = false;
  muLAB: boolean = false;
  muHSV: boolean = false;
  min: boolean = true;
  max: boolean = true;
  numPix: boolean = true;
  hex: boolean = true;
}

const thStyle = { textAlign: "center", width: 40 };
const trStyle = {
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
};

function render_th(name: string, border: boolean = false) {
  return (
    <th
      style={{
        textAlign: "center",
        width: 40,
        borderRight: border ? "1px solid rgba(0, 0, 0, 0.2)" : undefined,
      }}
    >
      {name}
    </th>
  );
}

function render_td(text: string | JSX.Element, border: boolean) {
  return (
    <td
      style={{
        textAlign: "center",
        borderRight: border ? "1px solid rgba(0, 0, 0, 0.2)" : undefined,
        fontSize: "8pt",
      }}
    >
      {text}
    </td>
  );
}
export function BlotchTable({
  blotches,
  cols,
  onSetName,
  onDeleteBlotch,
}: {
  blotches: Blotch[];
  cols: ShowCols;
  onSetName: (blotchIdx: number, newName: string) => void;
  onDeleteBlotch: (blotchIdx: number) => void;
}) {
  return (
    <div
      style={{
        alignSelf: "start",
        borderRadius: 5,
        height: "100%",
        // width: "500px",
        // overflow: "scroll",
        // marginRight: "20px",  
      }}
    >
      {/* <table>
        <tbody>
          <tr>
            <th>Mean R</th>
            <th>Mean G</th>
            <th>Mean B</th>
          </tr>
          {blotches.map((b) => (
            <tr>
              {b.meanRGB.toArray().map((val) => (
                <td>{val}</td>
              ))}
              )
            </tr>
          ))}
        </tbody>
      </table> */}
      <table
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: "1px solid rgba(0, 0, 0, 0.2)",
          borderRadius: 5,
          margin: 3,
          borderCollapse: "collapse",
        }}
      >
        <thead
          style={{
            borderBottom: "1px solid gray",
            borderRadius: 5,
          }}
        >
          <tr style={trStyle}>
            {/* {["🎨", "μR", "μG", "μB","%R", "%G", "%B", "σR", "σG", "σB", "px", "➖"].map((header, idx) => {
              console.log(idx, idx % 3 == 1);
              return (
              <th style = {{
                textAlign: "center",
                width: 40,
                borderRight: idx % 3 == 0 ? "1px solid rgba(0, 0, 0, 0.2)" : undefined
              }}>{header}</th>
              );
            })} */}
            {[render_th("🎨", true)]
              .concat(cols.name ? [render_th("ID", true)] : [])
              .concat(
                cols.muRGB
                  ? [
                      ...(cols.muR
                        ? [render_th("μR", !cols.muG && !cols.muB)]
                        : []),
                      ...(cols.muG ? [render_th("μG", !cols.muB)] : []),
                      ...(cols.muB ? [render_th("μB", true)] : []),
                    ]
                  : []
              )
              .concat(
                cols.percRGB
                  ? [
                      ...(cols.percR
                        ? [render_th("%R", !cols.percG && !cols.percB)]
                        : []),
                      ...(cols.percG ? [render_th("%G", !cols.percB)] : []),
                      ...(cols.percB ? [render_th("%B", true)] : []),
                    ]
                  : []
              )
              .concat(
                cols.sigmaRGB
                  ? [
                      ...(cols.sigmaR
                        ? [render_th("σR", !cols.sigmaG && !cols.sigmaB)]
                        : []),
                      ...(cols.sigmaG ? [render_th("σG", !cols.sigmaB)] : []),
                      ...(cols.sigmaB ? [render_th("σB", true)] : []),
                    ]
                  : []
              )
              .concat(
                cols.muCMYK
                  ? [
                      render_th("μC"),
                      render_th("μM"),
                      render_th("μY"),
                      render_th("μK", true),
                    ]
                  : []
              )
              .concat(
                cols.muLAB
                  ? [render_th("μL"), render_th("μA"), render_th("μB", true)]
                  : []
              )
              .concat(
                cols.muHSV
                  ? [render_th("μH"), render_th("μS"), render_th("μV", true)]
                  : []
              )
              .concat(cols.numPix ? [render_th("px", true)] : [])
              .concat(cols.hex ? [render_th("hex", true)] : [])
              .concat([render_th("➖", false)])}
          </tr>
        </thead>
        <tbody>
          {blotches.map((blotch: Blotch, idx: number) => (
            <tr style={trStyle}>
              {[
                <td
                  style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: `rgb(${[
                        blotch.meanRGB.R,
                        blotch.meanRGB.G,
                        blotch.meanRGB.B,
                      ].join(",")})`,
                      width: 25,
                      marginLeft: 7.5,
                      height: 25,
                      // height: "100%",
                      borderRadius: 2,
                    }}
                  />
                </td>,
              ]
                .concat(
                  cols.name
                    ? [
                        render_td(
                          <textarea
                            rows={1}
                            cols={5}
                            onChange={(
                              event: React.ChangeEvent<HTMLTextAreaElement>
                            ) => {
                              let newName: string = event.target.value;
                              onSetName(idx, newName);
                              // alert(newName);
                              // this.props.request(
                              //   this.props.baseURL +
                              //     `/set_pick_name/${
                              //       this.props.content.getBlotchidsList()[idx]
                              //     }/${newName}`
                              // );
                            }}
                            // value={row.getPickname()}
                            style={{
                              // backgroundColor: "dimgray",
                              // color: "white",
                              fontWeight: "bold",
                              width: "90%",
                            }}
                          >
                            {blotch.name}
                          </textarea>,
                          true
                        ),
                      ]
                    : []
                )
                .concat(
                  cols.muRGB
                    ? [
                        ...(cols.muR
                          ? [
                              render_td(
                                blotch.meanRGB.R.toFixed(2),
                                !cols.muG && !cols.muB
                              ),
                            ]
                          : []),
                        ...(cols.muG
                          ? [render_td(blotch.meanRGB.G.toFixed(2), !cols.muB)]
                          : []),
                        ...(cols.muB
                          ? [render_td(blotch.meanRGB.B.toFixed(2), true)]
                          : []),
                      ]
                    : []
                )
                .concat(
                  cols.percRGB
                    ? [
                        // render_td((blotch.percRGB.R * 100).toFixed(1), false),
                        // render_td((blotch.percRGB.G * 100).toFixed(1), false),
                        // render_td((blotch.percRGB.B * 100).toFixed(1), true),
                        ...(cols.percR
                          ? [
                              render_td(
                                blotch.percRGB.R.toFixed(2),
                                !cols.percG && !cols.percB
                              ),
                            ]
                          : []),
                        ...(cols.percG
                          ? [
                              render_td(
                                blotch.percRGB.G.toFixed(2),
                                !cols.percB
                              ),
                            ]
                          : []),
                        ...(cols.percB
                          ? [render_td(blotch.percRGB.B.toFixed(2), true)]
                          : []),
                      ]
                    : []
                )
                .concat(
                  cols.sigmaRGB
                    ? [
                        // render_td(blotch.sigmaRGB.R.toFixed(2), false),
                        // render_td(blotch.sigmaRGB.G.toFixed(2), false),
                        // render_td(blotch.sigmaRGB.B.toFixed(2), true),
                        ...(cols.sigmaR
                          ? [
                              render_td(
                                blotch.sigmaRGB.R.toFixed(2),
                                !cols.sigmaG && !cols.sigmaB
                              ),
                            ]
                          : []),
                        ...(cols.sigmaG
                          ? [
                              render_td(
                                blotch.sigmaRGB.G.toFixed(2),
                                !cols.sigmaB
                              ),
                            ]
                          : []),
                        ...(cols.sigmaB
                          ? [render_td(blotch.sigmaRGB.B.toFixed(2), true)]
                          : []),
                      ]
                    : []
                )
                .concat(
                  cols.muCMYK
                    ? [
                        render_td(getCMYK(blotch.meanRGB).C.toFixed(2), false),
                        render_td(getCMYK(blotch.meanRGB).M.toFixed(2), false),
                        render_td(getCMYK(blotch.meanRGB).Y.toFixed(2), false),
                        render_td(getCMYK(blotch.meanRGB).K.toFixed(2), true),
                      ]
                    : []
                )
                .concat(
                  cols.muLAB
                    ? [
                        render_td(getLAB(blotch.meanRGB).L.toFixed(2), false),
                        render_td(getLAB(blotch.meanRGB).A.toFixed(2), false),
                        render_td(getLAB(blotch.meanRGB).B.toFixed(2), true),
                      ]
                    : []
                )
                .concat(
                  cols.muHSV
                    ? [
                        render_td(getHSV(blotch.meanRGB).H.toFixed(2), false),
                        render_td(getHSV(blotch.meanRGB).S.toFixed(2), false),
                        render_td(getHSV(blotch.meanRGB).V.toFixed(2), true),
                      ]
                    : []
                )
                .concat(
                  cols.numPix
                    ? [render_td(blotch.numPixel.toString(), true)]
                    : []
                )
                .concat(
                  cols.hex ? [render_td(getHex(blotch.meanRGB), true)] : []
                )
                .concat([
                  <td
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <button
                      style={{
                        marginLeft: 2,
                        marginRight: 2,
                        backgroundColor: "rgba(255, 255, 255, 0.4)",
                        border: "1px solid rgba(0, 0, 0, 0.5)",
                        height: "100%",
                        fontSize: "8pt",
                        display: "flex",
                      }}
                      onClick={() => {
                        onDeleteBlotch(idx);
                      }}
                    >
                      ➖
                    </button>
                  </td>,
                ])}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
