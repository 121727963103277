import { Button, SxProps, Tooltip } from "@mui/material";
import React from "react";
import { Row, Col } from "./layout";

export const MaybeWithTooltip = ({
  children,
  tooltip,
}: {
  children: React.ReactNode;
  tooltip?: string;
}) => {
  if (tooltip) {
    return <Tooltip title={tooltip}>{children as any}</Tooltip>;
  } else {
    return <>{children}</>;
  }
};

export const Widget = ({
  children,
  icon,
  tooltip,
}: {
  children: React.ReactNode;
  icon: string;
  tooltip?: string;
}) => (
  <MaybeWithTooltip tooltip={tooltip}>
    <Row
      sx={{
        boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.3)",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        height: "30px",
        maxHeight: "30x",
        px: 1,
        borderRadius: "7px",
        mb: 1,
        fontWeight: 500,
        fontSize: "8",
      }}
    >
      <img src={icon} width="20px" style={{ marginRight: "5px" }} />
      {children}
    </Row>
  </MaybeWithTooltip>
);

export const TrigitButton = ({
  children,
  onClick,
  active,
  tooltip,
  width = "35px",
  height = "35px",
  sx = {},
}: {
  children: React.ReactNode;
  onClick?: (e: any) => void;
  active?: boolean;
  tooltip?: string;
  width?: any;
  height?: any;
  sx?: SxProps;
}) => {
  return (
    <MaybeWithTooltip tooltip={tooltip}>
      <Button
        onClick={onClick}
        sx={{
          boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.3)",
          border: "1px solid rgba(0, 0, 0, 0.2)",
          px: "1px",
          maxWidth: width, // For some reason, this is needed to set the width/height of the button.
          minWidth: width,
          maxHeight: height,
          minHeight: height,
          borderRadius: "5px",
          textTransform: "none",
          // filter: active ? "invert(100%)" : undefined,
          color: "black",
          backgroundColor: active ? "#afabab" : "#f2f3f6",
          "&:hover": {
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
            backgroundColor: "#d1d5e3",
          },
          ...sx,
        }}
      >
        {children}
      </Button>
    </MaybeWithTooltip>
  );
};

export const SVGButton = ({
  src,
  active,
  onClick,
}: {
  src: string;
  active?: boolean;
  onClick: () => void;
}) => (
  <TrigitButton active={active} onClick={onClick}>
    <img
      style={{ margin: "2px", filter: active ? "invert(100%)" : undefined }}
      src={src}
    />
  </TrigitButton>
);
