import cv from "@techstark/opencv-js";
import { Point } from "./MainCanvas";
import { BlotchROI } from "./Structs";

export const getConnectedComponent = (
  fullImage: cv.Mat,
  point: Point,
  floodThreshold: number
): BlotchROI => {
  (window as any).cv = cv;

  const mask = new cv.Mat(
    fullImage.rows + 2,
    fullImage.cols + 2,
    cv.CV_8U,
    [0, 0, 0, 0]
  );
  const rect = {};
  const cvPoint = new cv.Point(Math.round(point.x), Math.round(point.y));
  const threshold = Math.round(floodThreshold);

  /* 
  FLOODFILL_FIXED_RANGE: flood fill based on difference in colour between current pixel and pixel at `point`.
  FLOODFILL_MASK_ONLY: input image is not changed (newVal is ignored), only the mask is changed.
  */
  let res = cv.floodFill(
    fullImage,
    mask,
    cvPoint,
    [255, 128, 0, 255],
    rect,
    [threshold, threshold, threshold, 0],
    [threshold, threshold, threshold, 0],
    cv.FLOODFILL_FIXED_RANGE | cv.FLOODFILL_MASK_ONLY
  );

  const roi = fullImage.roi(rect as cv.Rect);

  // First roi removes the 1 px border required by floodFill.
  const borderlessRoi = mask.roi({ x: 1, y: 1, ...fullImage.size() });
  const maskRoi = borderlessRoi.roi(rect as cv.Rect);

  mask.delete();
  borderlessRoi.delete();

  return { roiRect: rect as cv.Rect, roi, mask: maskRoi };

  // return { roi, mask };
  // return { roi: new cv.Mat(), mask: new cv.Mat() };
};
