import cv from "@techstark/opencv-js";
import { ShowCols } from "./BlotchTable";
import { Point } from "./MainCanvas";
import { BlotchROI, CMYK, HSV, LAB, RGB } from "./Structs";
import Color from "colorjs.io";

let nextBlotchId = 0;

export type Blotch = {
  meanRGB: RGB;
  percRGB: RGB;
  sigmaRGB: RGB;
  meanCMYK: CMYK;
  meanLAB: LAB;
  meanHSV: HSV;
  numPixel: number;
  hex: string;
  name: string;
  vizMat: cv.Mat;
  boundingRect: cv.Rect;
  // contour: cv.Mat;

  // center: Point;
  // radius: number;
  // imgRoi: cv.Mat;
  // vizMat: cv.Mat;
  // meanRGB: RGB;
  // percRGB: RGB;
  // sigmaRGB: RGB;
  // numPixel: number;
  // name: string;

  // constructor(center: Point, radius: number, imgRoi: cv.Mat) {
  //   this.center = center;
  //   this.radius = radius;
  //   this.imgRoi = imgRoi;

  //   this.meanRGB = getMeanRGB(imgRoi, center, radius);
  //   this.percRGB = this.meanRGB;
  //   this.sigmaRGB = getSigmaRGB(imgRoi, center, radius);
  //   this.numPixel = Math.round(this.radius * this.radius * 3.141592653589793)
  //   this.vizMat = createViz(imgRoi, center, radius, this.meanRGB);

  //   this.name = "";
  // }
};

export const makeBlotch = ({ roiRect, roi, mask }: BlotchROI): Blotch => {
  const meanRGB = computeMeanRGB(roi, mask);
  const sigmaRGB = computeSigmaRGB(roi, mask);
  const percRGB = computePercRGB(meanRGB);
  const meanCMYK = getCMYK(meanRGB);
  const meanLAB = getLAB(meanRGB);
  const meanHSV = getHSV(meanRGB);
  const numPixel = cv.countNonZero(mask);
  const hex = getHex(meanRGB);
  const name = "";
  const contour = getContour(mask);
  const vizMat = makeViz(roi, contour, meanRGB);

  roi.delete();
  mask.delete();

  return {
    meanRGB,
    sigmaRGB,
    percRGB,
    meanCMYK,
    meanLAB,
    meanHSV,
    numPixel,
    hex,
    name,
    vizMat,
    boundingRect: roiRect,
  };
};

export const getHex = (rgb: RGB): string => {
  const digitToHex = (d: number): string => {
    const hex = Math.round(d).toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };

  return "#" + digitToHex(rgb.R) + digitToHex(rgb.G) + digitToHex(rgb.B);
};

export const getCMYK = (rgb: RGB): CMYK => {
  let c = 1 - rgb.R / 255;
  let m = 1 - rgb.G / 255;
  let y = 1 - rgb.B / 255;
  let k = Math.min(c, m, y);

  c = Math.round(((c - k) / (1 - k)) * 100);
  m = Math.round(((m - k) / (1 - k)) * 100);
  y = Math.round(((y - k) / (1 - k)) * 100);
  k = Math.round(k * 100);

  return {
    C: c,
    M: m,
    Y: y,
    K: k,
  };
};

export const getLAB = (rgb: RGB): LAB => {
  const lab = rgbToColor(rgb).lab;
  return {
    L: lab[0],
    A: lab[1],
    B: lab[2],
  };
};

export const getHSV = (rgb: RGB): HSV => {
  const hsv = rgbToColor(rgb).hsv;
  return {
    H: hsv[0],
    S: hsv[1],
    V: hsv[2],
  };
};

const rgbToColor = (rgb: RGB): Color =>
  new Color(`rgb(${rgb.R}, ${rgb.G}, ${rgb.B})`);

const getContour = (mask: cv.Mat): cv.MatVector => {
  const contours = new cv.MatVector();
  const hierarchy = new cv.Mat();
  cv.findContours(
    mask,
    contours,
    hierarchy,
    cv.RETR_LIST,
    cv.CHAIN_APPROX_SIMPLE
  );
  return contours;
};

const makeViz = (roi: cv.Mat, contour: cv.MatVector, meanRGB: RGB): cv.Mat => {
  const viz1 = new cv.Mat();
  const viz2 = new cv.Mat();
  roi.copyTo(viz1);
  roi.copyTo(viz2);

  cv.drawContours(viz1, contour, -1, new cv.Scalar(0, 255, 0, 255), 1);

  cv.rectangle(
    viz2,
    new cv.Point(0, 0),
    new cv.Point(1, viz2.size().height),
    new cv.Scalar(0, 0, 0, 255),
    -1
  );
  cv.rectangle(
    viz2,
    new cv.Point(viz2.size().width / 2, 0),
    new cv.Point(viz2.size().width, viz2.size().height),
    new cv.Scalar(meanRGB.R, meanRGB.G, meanRGB.B, 255),
    -1
  );

  const ret = new cv.Mat();
  const matVec = new cv.MatVector();
  matVec.push_back(viz1);
  matVec.push_back(viz2);
  cv.hconcat(matVec, ret);
  viz1.delete();
  viz2.delete();
  matVec.delete();
  return ret;
};

function createViz(
  imgRoi: cv.Mat,
  center: Point,
  radius: number,
  meanRGB: RGB
): cv.Mat {
  let viz = new cv.Mat();
  imgRoi.copyTo(viz);

  cv.circle(
    viz,
    new cv.Point(center.x, center.y),
    radius,
    [0, 255, 255, 255],
    3
  );

  let colourRect = new cv.Rect(radius, 0, radius, radius * 2);

  cv.rectangle(
    viz,
    new cv.Point(radius, 0),
    new cv.Point(2 * radius, 2 * radius),
    new cv.Scalar(meanRGB.R, meanRGB.G, meanRGB.B, 255),
    -1
  );

  return viz;
}

const computeMeanRGB = (roi: cv.Mat, mask: cv.Mat): RGB => {
  let rgb = cv.mean(roi, mask);
  return new RGB(rgb[0], rgb[1], rgb[2]);
};

const computeSigmaRGB = (roi: cv.Mat, mask: cv.Mat): RGB => {
  let mean = new cv.Mat();
  let std = new cv.Mat();
  let res = cv.meanStdDev(roi, mean, std, mask);
  let data = std.data64F;
  const rgb = new RGB(data[0], data[1], data[2]);
  mean.delete();
  std.delete();
  return rgb;
};

const computePercRGB = (meanRGB: RGB) => {
  const sum = meanRGB.R + meanRGB.G + meanRGB.B;
  return new RGB(meanRGB.R / sum, meanRGB.G / sum, meanRGB.B / sum);
};

function getMeanRGB(imgRoi: cv.Mat, center: Point, radius: number): RGB {
  const mask = cv.Mat.zeros(imgRoi.size(), 0);
  cv.circle(mask, new cv.Point(radius, radius), radius, [255, 0, 0, 0], -1);
  let rgb = cv.mean(imgRoi, mask);
  return new RGB(rgb[0], rgb[1], rgb[2]);
  // return new RGB(Math.round(rgb[0]), Math.round(rgb[1]), Math.round(rgb[2]));
}

function getSigmaRGB(imgRoi: cv.Mat, center: Point, radius: number): RGB {
  const mask = cv.Mat.zeros(imgRoi.size(), 0);
  cv.circle(mask, new cv.Point(radius, radius), radius, [255, 0, 0, 0], -1);

  let mean = new cv.Mat();
  let std = new cv.Mat();
  let res = cv.meanStdDev(imgRoi, mean, std, mask);
  console.log(res, mean, std);
  let data = std.data64F;
  return new RGB(data[0], data[1], data[2]);
}

type BlotchNameDefinition = { [k in keyof Blotch]: any };

const blotchFieldNames: Partial<BlotchNameDefinition> = {
  name: "ID",
  meanRGB: {
    R: "μR",
    G: "μG",
    B: "μB",
  },
  percRGB: {
    R: "%R",
    G: "%G",
    B: "%B",
  },
  sigmaRGB: {
    R: "σR",
    G: "σG",
    B: "σB",
  },
  meanCMYK: {
    C: "μC",
    M: "μM",
    Y: "μY",
    K: "μK",
  },
  meanLAB: {
    L: "μL",
    A: "μA",
    B: "μB",
  },
  meanHSV: {
    H: "μH",
    S: "μS",
    V: "μV",
  },
  numPixel: "px",
  hex: "hex",
};

export function getClipboardStr(
  blotches: Blotch[],
  showCols: ShowCols,
  withHeader: boolean
): string {
  return (withHeader ? [blotchFieldNames, ...blotches] : blotches)
    .map((b) => {
      let arr = [];
      const sc = showCols;

      sc.name && arr.push(b.name);
      if (sc.muRGB) {
        sc.muR && arr.push(b.meanRGB.R);
        sc.muG && arr.push(b.meanRGB.G);
        sc.muB && arr.push(b.meanRGB.B);
      }
      if (sc.percRGB) {
        sc.percR && arr.push(b.percRGB.R);
        sc.percG && arr.push(b.percRGB.G);
        sc.percB && arr.push(b.percRGB.B);
      }
      if (sc.sigmaRGB) {
        sc.sigmaR && arr.push(b.sigmaRGB.R);
        sc.sigmaG && arr.push(b.sigmaRGB.G);
        sc.sigmaB && arr.push(b.sigmaRGB.B);
      }
      if (sc.muCMYK) {
        arr.push(b.meanCMYK.C, b.meanCMYK.M, b.meanCMYK.Y, b.meanCMYK.K);
      }
      if (sc.muLAB) {
        arr.push(b.meanLAB.L, b.meanLAB.A, b.meanLAB.B);
      }
      if (sc.muHSV) {
        arr.push(b.meanHSV.H, b.meanHSV.S, b.meanHSV.V);
      }
      sc.numPix && arr.push(b.numPixel);
      sc.hex && arr.push(b.hex);

      return arr.join("\t");
      // b.meanRGB.toArray().join("\t")
    })
    .join("\n");
}

export const copyToClipboard = (
  blotches: Blotch[],
  showCols: ShowCols,
  withHeader: boolean
) => {
  const str = getClipboardStr(blotches, showCols, withHeader);
  navigator.clipboard.writeText(str);
};
