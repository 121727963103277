import { Point } from "./MainCanvas";
import { SelectionMode } from "./SideBar";
import cv from "@techstark/opencv-js";
import { Blotch, makeBlotch } from "./Blotch";
import { getConnectedComponent } from "./pick";
import { BlotchROI } from "./Structs";

export type CircleSelect = {
  center: Point;
  radius: number;
};

export type RectSelect = {
  topLeft: Point;
  bottomRight: Point;
};

export type PickSelect = {
  point: Point;
};

export type ShapeSelect = {
  mode: SelectionMode;
  isComplete: boolean;
  shape: CircleSelect | RectSelect | PickSelect;
};

const getCircleRadius = (center: Point, pointer: Point): number => {
  return (
    Math.sqrt((pointer.x - center.x) ** 2 + (pointer.y - center.y) ** 2) / 5
  );
};

const updateCircle = (
  shape: CircleSelect,
  pointerLoc: Point
): CircleSelect => ({
  center: shape.center,
  radius: getCircleRadius(shape.center, pointerLoc),
});

const applyScaleCorrection = (
  select: ShapeSelect,
  scale: number
): ShapeSelect => {
  if (select.mode == "circle") {
    let shape = select.shape as CircleSelect;
    return {
      mode: select.mode,
      isComplete: select.isComplete,
      shape: {
        center: {
          x: shape.center.x / scale,
          y: shape.center.y / scale,
        },
        radius: shape.radius / scale,
      },
    };
  } else if (select.mode == "rect") {
    let shape = select.shape as RectSelect;
    return {
      mode: select.mode,
      isComplete: select.isComplete,
      shape: {
        topLeft: {
          x: shape.topLeft.x / scale,
          y: shape.topLeft.y / scale,
        },
        bottomRight: {
          x: shape.bottomRight.x / scale,
          y: shape.bottomRight.y / scale,
        },
      },
    };
  } else if (select.mode === "pick") {
    let shape = select.shape as PickSelect;
    return {
      mode: select.mode,
      isComplete: select.isComplete,
      shape: {
        point: {
          x: shape.point.x / scale,
          y: shape.point.y / scale,
        },
      },
    };
  } else {
    throw "not implemented";
  }
};

const shapeSelectToRoiAndMask = (
  fullImage: cv.Mat,
  select: ShapeSelect,
  scale: number,
  floodThreshold: number
): BlotchROI => {
  /* 
  Given a full image (i.e. the uploaded image, at original size), a selection shape, 
  and the scale at which the shape was drawn, return a rectangular ROI which bounds the 
  selection shape, and a mask within this ROI with value 1 within the shape and 0 outside. 
  */

  const fullSelect = applyScaleCorrection(select, scale);

  if (fullSelect.mode == "circle") {
    const shape = fullSelect.shape as CircleSelect;
    const roiRect = new cv.Rect(
      shape.center.x - shape.radius,
      shape.center.y - shape.radius,
      2 * shape.radius,
      2 * shape.radius
    );
    const roi = fullImage.roi(roiRect);
    const mask = cv.Mat.zeros(roi.size(), 0);
    cv.circle(
      mask,
      new cv.Point(shape.radius, shape.radius),
      shape.radius,
      [255, 0, 0, 0],
      -1
    );
    return { roiRect, roi, mask };
  } else if (fullSelect.mode == "rect") {
    const shape = fullSelect.shape as RectSelect;

    const roiRect = new cv.Rect(
      shape.topLeft.x,
      shape.topLeft.y,
      shape.bottomRight.x - shape.topLeft.x,
      shape.bottomRight.y - shape.topLeft.y
    );
    const roi = fullImage.roi(roiRect);
    const mask = cv.Mat.zeros(roi.size(), 0);
    cv.rectangle(
      mask,
      new cv.Point(0, 0),
      new cv.Point(roi.cols, roi.rows),
      [255, 0, 0, 0],
      -1
    );
    return { roiRect, roi, mask };
  } else if (fullSelect.mode === "pick") {
    const shape = fullSelect.shape as PickSelect;

    return getConnectedComponent(fullImage, shape.point, floodThreshold);
  } else {
    throw "not implemented";
  }
};

export const startSelectShape = (
  selectionMode: SelectionMode,
  pointerLoc: Point
): ShapeSelect => {
  if (selectionMode === "circle") {
    return {
      mode: selectionMode,
      isComplete: false,
      shape: {
        center: pointerLoc,
        radius: 0,
      },
    };
  } else if (selectionMode == "rect") {
    return {
      mode: selectionMode,
      isComplete: false,
      shape: {
        topLeft: pointerLoc,
        bottomRight: pointerLoc,
      },
    };
  } else if (selectionMode == "pick") {
    return {
      mode: selectionMode,
      isComplete: true,
      shape: {
        point: pointerLoc,
      },
    };
  } else {
    console.log("OnStartDrawing", selectionMode);
    throw "not implemented";
  }
};

export const dragUpdateSelectShape = (
  select: ShapeSelect,
  pointerLoc: Point
): ShapeSelect => {
  if (select.mode === "circle") {
    return {
      mode: select.mode,
      isComplete: false,
      shape: updateCircle(select.shape as CircleSelect, pointerLoc),
    };
  } else if (select.mode === "rect") {
    return {
      mode: select.mode,
      isComplete: false,
      shape: {
        topLeft: (select.shape as RectSelect).topLeft,
        bottomRight: pointerLoc,
      },
    };
  } else {
    console.log("OnUpdateDrawing", select);
    return select;
  }
};

export const centerShapeSelectStampAt = (
  select: ShapeSelect,
  pointerLoc: Point
): ShapeSelect => {
  if (select.mode === "circle") {
    const shape = select.shape as CircleSelect;
    return {
      ...select,
      shape: {
        radius: shape.radius,
        center: pointerLoc,
      },
    };
  } else if (select.mode === "rect") {
    const shape = select.shape as RectSelect;
    const width = shape.bottomRight.x - shape.topLeft.x;
    const height = shape.bottomRight.y - shape.topLeft.y;
    return {
      ...select,
      shape: {
        topLeft: {
          x: pointerLoc.x - width / 2,
          y: pointerLoc.y - height / 2,
        },
        bottomRight: {
          x: pointerLoc.x + width / 2,
          y: pointerLoc.y + height / 2,
        },
      },
    };
  } else {
    console.log("OnUpdateDrawing", select);
    return select;
  }
};

export const clickUpdateSelectShape = (
  select: ShapeSelect,
  pointerLoc: Point
): ShapeSelect => {
  if (select.mode === "circle") {
    return {
      mode: select.mode,
      isComplete: true,
      shape: updateCircle(select.shape as CircleSelect, pointerLoc),
    };
  } else if (select.mode === "rect") {
    return {
      mode: select.mode,
      isComplete: true,
      shape: {
        topLeft: (select.shape as RectSelect).topLeft,
        bottomRight: pointerLoc,
      },
    };
  } else {
    console.log("OnUpdateDrawing", select);
    return select;
  }
};

export const renderSelectShape = (
  select: ShapeSelect,
  viewImgMat: cv.Mat,
  canvas: HTMLCanvasElement
) => {
  if (select.mode === "circle") {
    let shape = select.shape as CircleSelect;
    let vizMat = new cv.Mat();
    viewImgMat.copyTo(vizMat);
    cv.circle(
      vizMat,
      new cv.Point(shape.center.x, shape.center.y),
      Math.max(shape.radius, 2),
      [0, 255, 0, 255],
      1,
      cv.LINE_AA
    );
    cv.imshow(canvas, vizMat);
    vizMat.delete();
  } else if (select.mode === "rect") {
    let shape = select.shape as RectSelect;
    let vizMat = new cv.Mat();
    viewImgMat.copyTo(vizMat);
    cv.rectangle(
      vizMat,
      new cv.Point(shape.topLeft.x, shape.topLeft.y),
      new cv.Point(shape.bottomRight.x, shape.bottomRight.y),
      [0, 255, 0, 255],
      1,
      cv.LINE_AA
    );
    cv.imshow(canvas, vizMat);
    vizMat.delete();
  } else {
    console.log("OnRenderDrawing", select);
  }
};

export const blotchFromSelectShape = (
  fullImage: cv.Mat,
  select: ShapeSelect,
  scale: number,
  floodThreshold: number
): Blotch => {
  const blotchROI = shapeSelectToRoiAndMask(
    fullImage,
    select,
    scale,
    floodThreshold
  );
  return makeBlotch(blotchROI);
};
