import {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import { Modal } from "@mui/material";
import cv from "@techstark/opencv-js";

import { IMG_CANVAS_STYLE } from "./Style";

export const BlotchVizPanel = ({ vizMats }: { vizMats: cv.Mat[] }) => (
  <>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        overflowX: "scroll",
        overflowY: "hidden",
        height: "155px",
        width: window.innerWidth * 0.98 - 200,
      }}
    >
      {vizMats
        .slice()
        .reverse()
        .map((vizMat, idx) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div>
              <BlotchViz vizMat={vizMat} />
            </div>
          </div>
        ))}
    </div>
  </>
);

const BlotchViz = ({ vizMat }: { vizMat: cv.Mat }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const drawImage = useCallback(
    (canvas: HTMLCanvasElement) => {
      cv.imshow(canvas, vizMat);
    },
    [vizMat]
  );

  return (
    <>
      <Canvas
        draw={drawImage}
        style={{ ...IMG_CANVAS_STYLE, height: "140px" }}
        onClick={() => setModalOpen(true)}
      />
      <Modal keepMounted open={modalOpen} onClose={() => setModalOpen(false)}>
        <Canvas
          draw={drawImage}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            maxHeight: "30%",
            maxWidth: "30%",
            minWidth: "30%",
            transform: "translate(-50%, -50%) scale(2)",
          }}
        />
      </Modal>
    </>
  );
};

interface CanvasProps extends React.HTMLAttributes<HTMLCanvasElement> {
  draw: (canvas: HTMLCanvasElement) => void;
}

// Wraps an HTML canvas with a draw callback to draw something to this canvas once it is rendered.
const Canvas = ({ draw, ...props }: CanvasProps) => {
  const canvasRef = createRef<HTMLCanvasElement>();

  useEffect(() => {
    const canvas = canvasRef.current;
    draw(canvas!);
  }, [draw, canvasRef]);

  return <canvas ref={canvasRef} {...props} />;
};
