import cv from "@techstark/opencv-js";

export class RGB {
    R: number
    G: number 
    B: number

    constructor(R: number, G: number, B: number) {
        this.R = R;
        this.G = G;
        this.B = B;
    }

    toArray(): number[] {
        return [this.R, this.G, this.B];
    }
}

export type CMYK = {
    C: number
    M: number
    Y: number
    K: number
}

export type LAB = {
    L: number
    A: number
    B: number
}

export type HSV = {
    H: number
    S: number
    V: number
}

/** Represents a blotch selected from the full image. */
export type BlotchROI = {
    /** Rectangle of the blotch ROI in the full image */
    roiRect: cv.Rect
    /** ROI of the full image containing the blotch (`= fullImage.roi(roiRect)`) */
    roi: cv.Mat
    /** Mask for `roi` with a value of `1` within the blotch and `0` outside */
    mask: cv.Mat
}